.fourth-section {
  padding: 30px;
  background-color: #ffffff;
}

.statistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-bottom: 40px;
  background-color: #f9f9f9;
}

.stat {
  flex: 2;
}

.stat h2 {
  text-align: center;
  font-size: 60px;
  color: #C92223;
}

.stat p {
  text-align: center;
  margin-top: 0px;
  margin-right: 25px;
  font-weight: 600;
  font-size: 16px;
  color: #555;
}

.product-range {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.product-menu {
  flex: 1;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #3333334d;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-menu h3 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}

.product-menu h3 span {
  color: #C92223;
  font-weight: bold;
}

.product-menu button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 32px;
  text-align: left;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.product-menu button.active {
  background-color: #C92223;
  color: #fff;
  transition: all 0.8s ease;
}

.product-menu .unique-system {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.product-menu .unique-system img {
  width: 250px;
  margin-right: 10px;
}

.product-menu .unique-system p {
  font-size: 22px;
  color: #000000;
}

.unique-system span {
  color: #C92223;
}

.product-showcase {
  flex: 3;
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #3333334d;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-showcase h3 {
  font-size: 36px;
  width: 140px;
  border-bottom: 2px solid #C92223;
  margin: 20px auto;
  color: #000000;
}

.showcase-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.product-card-fourth {
  margin: 0 auto;
  width: 400px;
  border-radius: 5px;
  padding: 0px;
}

.product-card-fourth img {
  width: 400px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.product-card-fourth p {
  text-align: left;
  font-size: 32px;
  padding: 4px;
  border-left: 5px solid #C92223;
  font-weight: bold;
  color: #000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .fourth-section {
    padding: 10px;
    background-color: #ffffff;
  }

  .statistics {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
  }

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .stat h2 {
    font-size: 32px;
    color: #C92223;
    margin: 0;
  }

  .stat p {
    font-size: 10px;
    font-weight: 600;
    color: #555;
    margin-top: 5px;
  }

  .product-range {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .product-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
  }

  .product-menu h3 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .product-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }

  .product-menu button {
    width: 70px;
    height: 28px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #ccc;
    padding: 2px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .product-menu button.active {
    background-color: #C92223;
    color: #fff;
  }

  .unique-system {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
  }

  .product-menu .unique-system {
    margin-top: 20px;
  }

  .product-menu .unique-system img {
    width: 90px;
    height: 90px;
    margin-top: 0px;
  }

  .product-menu .unique-system p {
    font-size: 14px;
    text-align: center;
    margin-top: 2px;
    color: #555;
  }

  .product-showcase {
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #3333334d;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .product-showcase h3 {
    font-size: 14px;
    text-align: center;
    color: #000;
    border-bottom: 1px solid #C92223;
    margin-bottom: 10px;
  }

  .showcase-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
    gap: 10px; /* Space between items */
    width: 100%; /* Ensure grid fits within the container */
  }

  .product-card-fourth {
    width: 100%; /* Ensure the card fits within the grid */
    height: auto;
    margin: 0 auto;
    border-radius: 4px;
    overflow: hidden;
  }

  .product-card-fourth img {
    width: 100%; /* Ensure the image fits within the card */
    height: auto;
    object-fit: cover;
  }
}