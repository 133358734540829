.third-section {
    position: relative;
    padding: 40px;
    text-align: center;
    background-color: #fff;
  }
  
  .third-section h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
    color: #000;
  }
  
  .third-section p {
    font-size: 18px;
    margin-bottom: 40px;
    color: #777;
  }
  
  .landmark-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 20px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  
  .landmark-card {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 10px;
  }
  
  .landmark-card img {
    width: 550px;
    height: auto;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  .landmark-card p {
    margin-top: 10px;
    font-size: 24px;
    color: #000;
  }
  
  .landmark-card .highlight {
    color: #C92223;
    font-weight: bold;
  }
  
  .third-section-arrow.arrow-left,
  .third-section-arrow.arrow-right {
    position: absolute;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
  }
  
  .third-section-arrow.arrow-left {
    left: 10px;
    top: 80%;
  }
  
  .third-section-arrow.arrow-right {
    right: 10px;
    top: 20%;
  }
  
  .third-section-arrow.arrow-left img,
  .third-section-arrow.arrow-right img {
    width: 60px;
    height: auto;
  }

/* Mobile View Styling */
@media (max-width: 768px) {
  .third-section {
    padding: 20px;
    text-align: center;
    background-color: #fff;
  }

  .third-section h2 {
    font-size: 16px; /* Heading font size for mobile */
    margin-bottom: 5px;
    color: #000;
  }

  .third-section p {
    font-size: 12px; /* Subheading font size for mobile */
    margin-bottom: 20px;
    color: #777;
  }

  .landmark-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
    gap: 10px; /* Spacing between cards */
    border-top: 1px solid #ddd; /* Border line for grid */
    border-left: 1px solid #ddd;
  }

  .landmark-card {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #ddd; /* Border below each card */
    border-right: 1px solid #ddd; /* Border on the right of each card */
    padding: 5px; /* Adjust padding for smaller view */
    background-color: #fff; /* Ensure a white background */
  }

  .landmark-card img {
    width: 162px; /* Image width for mobile */
    height: 116px; /* Image height for mobile */
    object-fit: cover; /* Ensure images maintain aspect ratio */
    margin: 0 auto; /* Center the image */
    display: block;
  }

  .landmark-card p {
    font-size: 12px; /* Font size for captions below images */
    margin-top: 10px;
    color: #000;
  }

  .landmark-card .highlight {
    color: #C92223;
    font-weight: bold;
  }

  .third-section-arrow.arrow-left img,
  .third-section-arrow.arrow-right img {
    width: 30px; /* Smaller arrow size for mobile */
    height: auto;
  }
}