.second-section {
    position: relative;
    padding: 40px;
    text-align: center;
    background-color: #fff;
  }
  
  .second-section h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 40px;
    color: #000;
  }
  
  .product-section {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 40px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 600px;
    padding: 20px;
  }
  
  .image-container {
    position: relative;
  }
  
  .image-container img {
    width: 100%;
    border-radius: 8px;
  }
  
  .enquiry-button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #C92223;
    background-color: transparent;
    border: 1.5px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow added here */
  }
  
  .enquiry-button:hover {
    background-color: #C92223;
    color: #fff;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
  }
  
  .arrow-left,
  .arrow-right {
    position: absolute;
    
    transform: translateY(-50%);
    z-index: 10;
  }
  
  .arrow-left {
    top: 50%;
    left: 10px;
  }
  
  .arrow-right {
    top: 10%;
    right: 10px;
  }
  
  .arrow-left img,
  .arrow-right img {
    width: 60px;
    height: auto;
  }
  

/* General styles remain the same */
.trusted-section {
    position: relative; /* Set position for arrow placement */
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .trusted-section h3 {
    font-size: 32px;
    margin-bottom: 0px;
    color: #000;
  }
  
  .trusted-section p {
    font-size: 18px;
    color: #777;
    margin-bottom: 20px;
  }
  
  .trusted-logos {
    display: flex;
    justify-content: center;
    gap: 160px;
    flex-wrap: nowrap;
  }
  
  .trusted-logos img {
    width: 100px;
    height: auto;
    filter: grayscale(0);
    transition: all 0.3s ease;
  }
  
  .trusted-logos img:hover {
    filter: grayscale(0);
  }
  
  /* Arrow styles for Trusted Section */
  .trusted-arrow {
    position: absolute;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
  }
  
  .trusted-arrow.arrow-left {
    left: 10px;
    top: 80%;
  }
  
  .trusted-arrow.arrow-right {
    right: 10px;
    top: 10%;
  }
  
  .trusted-arrow img {
    width: 60px;
    height: auto;
  }


/* Mobile View Styling */
@media (max-width: 768px) {
  .second-section {
    padding: 10px;
    text-align: center;
    background-color: #fff;
  }

  .second-section h2 {
    font-size: 16px; /* Adjust heading font size */
    margin-bottom: 20px;
    color: #000;
  }

  .product-section {
    display: flex;
    flex-wrap: nowrap; /* Products in a single row */
    gap: 1px;
  }

  .product-card {
    width: 500px; /* Adjusted width for proper spacing */
    display: flex; /* Enable flexbox for alignment */
    flex-direction: column; /* Stack image and button vertically */
    align-items: center; /* Center image and button horizontally */
    justify-content: center; /* Center content within the card */
    padding: 2px;
  }

  .image-container img {
      width: 110%;
      height: 120px;
  }

  .arrow-left img,
  .arrow-right img {
    width: 30px;
    height: auto;
  }

  .arrow-left{
    bottom: 40%;
  }
  
  .arrow-right {
    top: 5%;
    right: 10px;
  }

  .enquiry-button {
    display: flex; /* Enable flexbox for alignment */
    justify-content: center; /* Horizontally center text */
    align-items: center; /* Vertically center text */
    width: 72px; /* Fixed width for mobile */
    height: 16px; /* Fixed height for mobile */
    padding: 10px; /* Remove any padding that might cause misalignment */
    font-size: 14px; /* Font size for smaller devices */
    font-weight: 600;
    color: #C92223;
    background-color: transparent;
    border: 1.5px solid #7a7a7a;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  }

  .trusted-section h3 {
    font-size: 16px;
  }

  .trusted-section p {
    font-size: 12px;
  }

  .trusted-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: nowrap;
  }

  .trusted-arrow.arrow-left {
    left: 10px;
    top: 90%;
  }
  
  .trusted-arrow.arrow-right {
    right: 10px;
    top: 10%;
  }

  .trusted-logos img {
    width: 55px;
    height: 55px;
  }
}