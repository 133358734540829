.footer-section {
    display: flex;
    justify-content: center ;
    align-items: center;
    background-color: #fff;
    padding: 20px 50px;
    position: relative;
  }
  
  .footer-content {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 90%; /* Adjust to align the content centrally */
    max-width: 1200px;
  }
  
  .footer-left {
    flex: 3; /* Adjust proportion */
    text-align: left;
  }
  
  .footer-logo {
    width: 150px;
    margin-bottom: 10px;
  }
  
  .footer-left p {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    margin: 0;
  }
  
  .footer-left .highlight {
    color: #C92223;
    font-weight: bold;
  }
  
  .footer-center {
    flex: 2; /* Adjust proportion */
    text-align: left;
  }
  
  .footer-contact {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-contact li {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .footer-contact li a{
    text-decoration: none;
    color: black;
  }
  
  .footer-divider {
    width: 1px;
    background-color: #ccc;
    height: 80%;
    margin: 0 20px;
  }
  
  .footer-right {
    flex: 1; /* Adjust proportion */
    text-align: left;
  }
  
  .footer-social {
    display: flex;
    gap: 15px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-social li {
    width: 30px;
  }
  
  .footer-social img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .footer-section {
      padding: 20px; /* Adjust padding for smaller screens */
    }
  
    .footer-content {
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center align all items */
      width: 100%; /* Full width for content */
    }
  
    .footer-left {
      flex: none; /* Remove flex proportion */
      text-align: left; /* Center align text for better readability */
      margin-bottom: 20px; /* Add spacing between sections */
    }
  
    .footer-logo {
      width: 120px; /* Adjust logo size for smaller screens */
      margin-bottom: 10px;
    }
  
    .footer-left p {
      font-size: 12px; /* Reduce font size */
      line-height: 1.4; /* Adjust line height */
      color: #333;
      margin: 0 10px; /* Add margin for spacing */
    }
  
    .footer-divider {
      display: none; /* Hide divider for smaller screens */
    }
  
    .footer-center {
      flex: none; /* Remove flex proportion */
      text-align: center; /* Center align contact details */
      margin-bottom: 20px; /* Add spacing between sections */
    }
  
    .footer-contact {
      text-align: center; /* Center align contact details */
    }
  
    .footer-contact li {
      font-size: 12px; /* Reduce font size */
      margin-bottom: 8px; /* Adjust spacing */
      justify-content: center; /* Center align list items */
      display: flex;
      align-items: center;
      gap: 5px;
    }
  
    .footer-right {
      flex: none; /* Remove flex proportion */
      text-align: center; /* Center align social icons */
      margin-bottom: 20px; /* Add spacing between sections */
    }
  
    .footer-social {
      justify-content: center; /* Center align social icons */
      gap: 10px; /* Adjust spacing between icons */
    }
  
    .footer-social li {
      width: 25px; /* Reduce icon size */
    }
  
    .footer-social img {
      width: 100%; /* Ensure icons fit their container */
      height: auto;
    }
  }
  