/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.landing-section {
  display: flex;
  flex-direction: row; /* Maintain row layout */
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.row {
  display: flex;
  width: 100%;
  height: 100%;
}

.image-container-landing{
  flex: 1;
  height: 100%;
  position: relative;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image scales proportionally */
}

.warranty-image {
  position: absolute;
  bottom: 10px;
  right: 600px;
  width: 160px; /* Set smaller width for small devices */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure it doesn’t exceed container width */
  max-height: 120px; /* Limit the height explicitly */
  object-fit: contain; /* Keep the proportions intact */
  z-index: 2;
}

.content-container {
  flex: 1;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px;
}

.logo-container {
  margin-bottom: 10px;
}

.logo {
  max-width: 150px;
  height: auto;
}

h1 {
  font-size: 46px;
  font-weight: bold;
  text-align: left;
  color: #C02026;
  margin-bottom: 10px;
  text-transform: uppercase;
}

h2 {
  font-size: 40px;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.form-container {
  width: 60%;
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: right; /* Align contents to the right */
}

.form-container h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
  text-align: left;
}

.form-container .highlight {
  color: #C02026;
  font-weight: bold;
}

.form-input {
  width: 95%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.form-button {
  width: auto;
  padding: 12px 24px;
  font-size: 16px;
  color: black;
  background-color: transparent;
  border: 1px solid #00000048;
  border-radius: 8px;
  cursor: pointer;
  float: right; /* Ensure the button aligns to the right */
}

.form-button:hover {
  background-color: #A81820;
  color: #fff;
}

/* Small-width devices (max-width: 768px) */
@media (max-width: 768px) {
  .landing-section {
    flex-direction: row; /* Maintain row layout */
    height: 350px; /* Ensure the section takes full viewport height */
  }

  .row {
    flex-direction: row; /* Keep both sections in a row */
    height: 100%; /* Ensure the row fills the parent height */
  }

  .image-container-landing {
    flex: 1;
    height: 400px; /* Ensure it fills the full height of the parent */
    position: relative;
  }

  .main-image {
    width: 100%;
    height: 350px; /* Ensure it takes the full height of the container */
  }

  .warranty-image {
    width: 40px; /* Smaller size for warranty image */
    max-height: 40px; /* Limit the height */
    bottom: 5px; /* Adjust position */
    left: 5px; /* Adjust position */
    object-fit: contain; /* Maintain aspect ratio */
  }

  .content-container {
    flex: 1;
    height: 100%; /* Match height with the image */
    padding: 20px; /* Reduced padding for smaller screens */
    align-items: flex-start; /* Maintain alignment */
  }

  .logo-container {
    text-align: left;
  }

  .logo {
    width: 80px; /* Smaller logo size */
  }

  h1 {
    font-size: 16px; /* Smaller heading size */
    margin-bottom: 10px;
  }

  h2 {
    font-size: 12px; /* Smaller subheading size */
    margin-bottom: 10px;
  }

  .form-container {
    width: 90%; /* Slightly reduced width for small devices */
    margin-top: 10px;
    padding: 5px; /* Reduced padding */
    border-radius: 6px;
    box-shadow: none; /* Remove shadow for small screens */
  }

  .form-container h3 {
    font-size: 16px; /* Smaller form title */
    margin-bottom: 10px;
  }

  .form-input {
    font-size: 12px; /* Smaller input size */
    padding: 2px;
  }

  .form-button {
    font-size: 12px; /* Smaller button text */
    padding: 8px 16px;
  }
}