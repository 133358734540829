.fifth-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 100px;
  height: 600px;
  position: relative;
  z-index: 1; /* Ensures the section content is above the overlay */
  }

  .fifth-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
    z-index: -1; /* Places the overlay behind the content */
  }
  
  .fifth-form-container,
  .fifth-right-section {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 35%;
  }
  
  .fifth-divider {
    width: 1px;
    background-color: #e0e0e0;
    height: 80%;
    margin: 0 20px;
  }
  
  .fifth-form-container h3,
  .fifth-right-section h3 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    color: #000;
  }
  
  .fifth-form-container form input {
    display: block;
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: none;
    border-bottom: 1px solid #000;
    font-size: 14px;
    background: transparent;
  }
  
  .fifth-requirement {
    margin-bottom: 15px;
  }
  
  .fifth-requirement label {
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
    font-weight: bold;
  }
  
  .fifth-requirement .fifth-buttons {
    display: flex;
    gap: 10px;
  }
  
  .fifth-requirement .fifth-buttons button {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    color: #000;
    background: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .fifth-buttons button:hover{
    background-color: #C92223;
    color: #fff;
    transition: all 0.8s ease;
  }
  
  .fifth-requirement .fifth-buttons button.fifth-active {
    background: #C92223;
    color: #fff;
    border-color: #C92223;
    transition: all 0.2s ease;
  }
  
  .fifth-submit-btn {
    width: 150px; /* Fixed width for submit button */
    padding: 12px;
    font-size: 14px;
    margin: 0 auto;
    color: #C92223;
    border: 1px solid #000;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    align-self: flex-start; /* Align button to left */
  }
  
  .fifth-submit-btn:hover {
    background: #C92223;
    color: #fff;
  }
  
/* Styling for <a> tags to replace buttons */
.fifth-right-section a {
  display: inline-block;
  align-items: center;
  text-align: center;
  text-decoration: none;
  width: 225px; /* Fixed width for right section links */
  padding: 12px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #C92223;
  border: 1px solid #000;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
}

.fifth-right-section a span {
  color: #000;
}

.fifth-right-section a:hover {
  background: #C92223;
  color: #000000;
}
  
  .fifth-right-section hr {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  .fifth-right-section .fifth-quotation-btn {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .fifth-section {
      flex-direction: column; /* Stack form and right section vertically */
      padding: 60px;
      height: auto; /* Allow height to adjust to content */
    }
  
    .fifth-divider {
      display: none; /* Hide divider for small screens */
    }
  
    .fifth-form-container,
    .fifth-right-section {
      width: 100%; /* Full width for both sections */
      margin-bottom: 20px; /* Add spacing between stacked sections */
    }
  
    .fifth-form-container h3,
    .fifth-right-section h3 {
      font-size: 16px; /* Adjust font size */
      margin-bottom: 15px;
    }
  
    .fifth-form-container form input {
      width: 100%; /* Full width for input fields */
      padding: 8px; /* Adjust padding */
      font-size: 12px; /* Smaller font size */
    }
  
    .fifth-requirement .fifth-buttons {
      flex-wrap: wrap; /* Wrap buttons to fit small screens */
      gap: 5px; /* Reduce gap between buttons */
    }
  
    .fifth-requirement .fifth-buttons button {
      flex: 1;
      display: flex; /* Added flexbox */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      padding: 15px;
      font-size: 14px;
      border: 1px solid #ccc;
      background: #f5f5f5;  
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center; /* Ensure text is aligned properly */
    }
  
    .fifth-submit-btn {
      width: 150px; /* Fixed width for submit button */
      display: flex; /* Added flexbox */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      padding: 20px;
      font-size: 14px;
      margin: 0 auto; /* Center button in its container */
      color: #C92223;
      border: 1px solid #000;
      background: transparent;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  
    .fifth-right-section a {
      width: 60%; /* Full width for links */
      padding: 10px;
      font-size: 14px;
      margin-bottom: 10px; /* Adjust spacing between links */
    }
  
    .fifth-right-section hr {
      margin: 10px 0; /* Reduce margin for smaller screens */
    }
  }
  